import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { loaderImage } from '../../../utils/image';

type Props = {
  src: string;
  className?: string;
  quality?: number;
  priority?: boolean;
  alt?: string;
  width?: number;
  height?: number;
};

const IconsImage = ({
  src,
  className = '',
  quality,
  priority,
  alt,
  width,
  height
}: Props) => {
  return (
    <Image
      src={src}
      loader={loaderImage}
      className={classnames('image-icon', className)}
      quality={quality || 50}
      priority={priority || false}
      alt={alt || ''}
      role={alt ? 'presentation' : null}
      width={width || 62}
      height={height || 62}
    />
  );
};

export default React.memo(IconsImage);
