import { featureTags } from './constants';
import { isOrganicSearch } from '../url';

function addFeaturePage() {
  let feature = featureTags.PRODUCT_PAGE;

  if (typeof document !== 'undefined' && isOrganicSearch(document?.referrer)) {
    feature = `${feature},${featureTags.ORGANIC}`;
  }

  return feature;
}

export const isValidFeature = (hotfeature: string) => {
  let mountFeatures = '';

  if (hotfeature) {
    const featuresFromUrl = hotfeature?.split(',');
    const featuresTagsList = Object.values(featureTags);
    let featuresFinalList = [];

    featuresFromUrl?.map((feature) => {
      if (featuresTagsList?.includes(feature)) {
        featuresFinalList.push(feature);

        if (feature === featureTags.COMMUNITY) {
          featuresFinalList.push(featureTags.PRODUCT_PAGE);
        }
      }
    });

    mountFeatures = featuresFinalList?.length
      ? featuresFinalList.join(',')
      : mountFeatures;
  }

  if (!mountFeatures) {
    mountFeatures = addFeaturePage();
  }

  return mountFeatures;
};
