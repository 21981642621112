import config from '../../env.config';

type ImageNextOptions = {
  src: string
};

export const loaderImage = ({ src, width }) => {
  return `${src}?w=${width || 300}`;
};

export const loaderStaticImage = ({ src }: ImageNextOptions) => {
  return `${config.envConfig.APP}/product/${src}`;
};

export const generateSrcSet = ({ src }) => {
  return `${src} 360w, ${src} 780w, ${src} 920w`;
};
