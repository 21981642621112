export const featureTags = {
  PRODUCT_PAGE: '32',
  MARKETPLACE: '33',
  ORGANIC: '34',
  COMMUNITY: '46'
};

export const featureSwitchKey = {
  PRODUCT_PRICES_GATEWAY: 'api-display-back-enable-payment-display-gateway'
};
