import { categories } from '../../constants';

function isCourseOnlineFormat(format: string) {
  return format === categories.COURSES_ONLINE;
}

function isServiceOnlineFormat(format: string) {
  return format === categories.SERVICE_ONLINE;
}

function isEventOnline(format: string) {
  return format === categories.EVENT_ONLINE;
}

export { isCourseOnlineFormat, isServiceOnlineFormat, isEventOnline };
