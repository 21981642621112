const sanitizeLanguage = (analyticsEvent) => {
  if (analyticsEvent.language) {
    analyticsEvent.language = `${analyticsEvent.language || ''}`.toLowerCase();
  }
  return analyticsEvent;
};

const addEnvironment = (analyticsEvent) => {
  analyticsEvent.deploymentEnvironment = `${process.env.ENV_CONFIG || ''}`;
  return analyticsEvent;
};

export const sendAnalyticsEvent = (analyticsEvent) => {
  try {
    if (typeof window !== 'undefined' && analyticsEvent) {
      window.dataLayer = window.dataLayer || [];
      analyticsEvent = sanitizeLanguage(analyticsEvent);
      analyticsEvent = addEnvironment(analyticsEvent);
      window.dataLayer.push(analyticsEvent);
    }
  } catch (err) {}
};

export default { sendAnalyticsEvent };
